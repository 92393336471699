import * as React from "react";
import "@fontsource/roboto/300.css"; // Defaults to weight 300 with all styles included.
import "@fontsource/roboto/400.css"; // Defaults to weight 400 with all styles included.
import "@fontsource/roboto/500.css"; // Defaults to weight 500 with all styles included.
import "@fontsource/roboto/700.css"; // Defaults to weight 700 with all styles included.
import "@fontsource/roboto/900.css"; // Defaults to weight 900 with all styles included.
import CollectionsPage from "./products/collections";
import Seo from "./Seo";
import { common } from "../util/constants";
// import Head from "./Head";

// styles

// markup

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <>
      <Seo 
     location={`${common.APP_FE_URL}${location.pathname}`}
     pageContext={pageContext}
     params={params}
      />
    </>
  );
};

const IndexPage = () => {
  React.useEffect(() => {
    (function (g, u, i, d, e, s) {
      g[e] = g[e] || [];
      var f = u.getElementsByTagName(i)[0];
      var k = u.createElement(i);
      k.async = true;
      k.src =
        "https://static.userguiding.com/media/user-guiding-" +
        s +
        "-embedded.js";
      f.parentNode.insertBefore(k, f);
      if (g[d]) return;
      var ug = (g[d] = { q: [] });
      ug.c = function (n) {
        return function () {
          ug.q.push([n, arguments]);
        };
      };
      var m = [
        "previewGuide",
        "finishPreview",
        "track",
        "identify",
        "triggerNps",
        "hideChecklist",
        "launchChecklist",
      ];
      for (var j = 0; j < m.length; j += 1) {
        ug[m[j]] = ug.c(m[j]);
      }
    })(
      window,
      document,
      "script",
      "userGuiding",
      "userGuidingLayer",
      "814741743ID"
    );
  }, []);

  // function handleNewEvent(event: EventHandlerPayload<'onNewEvent'>) {
  //   console.log('LiveChatWidget.onNewEvent', event)
  // }

  return (
    <>
      <CollectionsPage />
    </>
  );
};

export default IndexPage;
