import React, { useEffect, useState } from "react";
import { common } from "../util/constants";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const Seo = ({ title, description, pathname, children, location, params }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
  };

  // const [titleLocal,setTitleLocal] = useState("AVmade")


  let titleLocal = "AVmade"

  const getTitle = () => {
    if (
      typeof window !== "undefined" &&
      location.includes(`${common.APP_FE_URL}/product/`)
    ) {
      titleLocal = localStorage.getItem("currentPageTitle");
    }
  };

  setInterval(() => {
    getTitle();
  }, 2000);

  return (
    <>
      <title>{
        (typeof window !== "undefined" &&
        location.includes(`${common.APP_FE_URL}/product/`) ) && localStorage.getItem("currentPageTitle")? 
        `${localStorage.getItem("currentPageTitle")}` : 'AVmade'
        }</title>
      <meta
        name="google-site-verification"
        content="Owt-k77hhqNxkKY6Jtz10Hn2TDMKE0uQzQY4pMV93mI"
      />
      <link
        rel="icon"
        type="image/x-icon"
        href="/images/common/av-favicon_64x64.png"
      />
      <meta name="msvalidate.01" content="513E2BD36064B012B4F4C772D470792D" />
      <link rel="canonical" href={`${location}`} />
      <meta name="description" content=" " />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="product" />
      <meta property="og:title" content={`${(typeof window !== "undefined" &&
        location.includes(`${common.APP_FE_URL}/product/`) )? 
        `${localStorage.getItem("currentPageTitle")}` : 'AVmade'
        }`} />
      <meta property="og:description" content=" " />
      <meta property="og:url" content={`${location}`} />
      <meta property="og:site_name" content="AVmade" />
      <meta property="og:image" content=" " />
      {children}
    </>
  );
};

export default Seo;
